<template>
  <div
    :style="
      'display: flex;align-items: center;flex-direction: column;overflow-y:scroll;overflow-x:hidden;height:' +
      hei
    "
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        margin-top: 2vh;
        align-items: center;
      "
    >
      <div
        style="
          width: 100vw;
          display: flex;
          justify-content: end;
          justify-content: flex-end;
          margin-right: 5vw;
        "
      >
        <el-dropdown
          size="medium"
          placement="bottom"
          @command="handleClickLocale"
        >
          <i
            class="iconfont icon-locale"
            style="cursor: pointer; font-size: 25px; color: rgba(0, 66, 166, 1)"
          ></i>
          <el-dropdown-menu slot="dropdown" class="menu-item">
            <el-dropdown-item command="zh">简体中文</el-dropdown-item>
            <el-dropdown-item command="en" divided>English </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <img
        src="../../assets/img/phoneLoginLogo.png"
        alt=""
        style="width: 80vw; margin-top: 6vh; margin-bottom: 10vh"
      />
    </div>
    <div
      class="topChange"
      :style="{ 'font-size': lang === 'en' ? '12px' : '16px' }"
    >
      <router-link
        to="/phoneloginp"
        class="clear"
        style="display: flex; align-items: center; width: 44vw"
      >
        <span class="noactive">{{ $t("addLogin.loginEmail.psd") }}</span>
      </router-link>
      <span class="active">{{ $t("addLogin.loginEmail.email2") }}</span>
    </div>
    <div class="main">
      <div class="inputbox">
        <div slot="reference" class="i_emial">
          <input
            id="email"
            v-model="email"
            type="eamil"
            class="email all"
            :placeholder="$t('addLogin.forgetPsd.place1')"
          /><i class="iconfont icon-email frontIcon"></i>
        </div>

        <div class="i_code">
          <input
            id="code"
            v-model="code"
            type="text"
            class="num all"
            :placeholder="$t('addLogin.forgetPsd.place3')"
          />
          <el-button
            type="primary"
            v-if="!issend"
            class="yzm"
            @click="sendCode"
          >
            <p style="white-space: nowrap">
              {{ $t("addLogin.forgetPsd.getCode") }}
            </p>
          </el-button>
          <el-button type="primary" v-else class="yzm1">
            <p id="yzm1" style="white-space: nowrap">
              {{ $t("addLogin.forgetPsd.message6") }}
            </p>
          </el-button>
        </div>
        <div class="forget"></div>
        <div class="lobtn">
          <el-button
            class="login_goto"
            type="primary"
            :loading="btnLoading"
            @click="handleLogin"
            >{{ $t("addLogin.loginEmail.login") }}</el-button
          >
          <div class="r2 router">
            <span>{{ $t("addLogin.forgetPsd.title4") }}&nbsp;</span>
            <router-link
              to="/phoneloginr"
              class="clear"
              style="display: flex; align-items: center"
            >
              <p>{{ $t("addLogin.register.register") }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 5vh">
      <third-party-login></third-party-login>
    </div>
  </div>
</template>

<script>
import { request_joinedProjects } from "@/network/personal/index.js";
import { sessionSave, sessionRead, sessionRemove } from "@/utils/session";
import { request_verify_code } from "@/network/login/index.js";
import Cookies from "js-cookie";
import { mapActions, mapMutations } from "vuex";
import { getWatermark } from "@/network/watermask";
import thirdPartyLogin from "@/components/thirdPartyLogin";

export default {
  components: {
    thirdPartyLogin,
  },
  data() {
    return {
      btnLoading: false,
      email: "",
      code: "",
      vercode: "",
      issend: false,
      timeo: 60,
      timeStop: "",
      hei: "",
      lang: "",
    };
  },
  beforeDestroy() {
    clearInterval(this.timeStop);
  },
  mounted() {
    this.hei = window.innerHeight + "px";
    this.lang = localStorage.getItem("lang");
  },
  methods: {
    ...mapMutations(["SET_WATERMASK"]),
    ...mapActions([
      "setTestPlan",
      "setVersion",
      "setSprint",
      "setBaseLine",
      "setUser",
      "setExecutionStatus",
      "setFileType",
      "setProjectUserList",
      "setStatus",
    ]),
    handleClickLocale(command) {
      localStorage.setItem("lang", command);
      window.location.reload();
    },
    set_release_obj(pid) {
      let i = pid ? pid : this.get_pid();
      this.setTestPlan(i);
      this.setVersion(i);
      this.setSprint(i);
      this.setBaseLine(i);
      this.setExecutionStatus(i);
      this.setFileType(i);
      this.setProjectUserList(i);
      this.setStatus(i);
    },
    check_email() {
      let emailRegExp = /^[\.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!emailRegExp.test(this.email)) {
        this.$message.warning(this.$t("addLogin.forgetPsd.message2"));
        return false;
      }
      return true;
    },
    sendCode() {
      if (this.check_email()) {
        this.issend = true;
        this.vercode = Math.random().toFixed(6).slice(-6);
        Cookies.set("vercode", this.vercode, {
          expires: 60000 / 864e5,
        });
        request_verify_code(this.email, "LOGIN").then((res) => {
          // this.$message.success(this.$t('addLogin.forgetPsd.message4'))
          this.$message({
            message: this.$t("addLogin.forgetPsd.message7"),
            type: "success",
            duration: 5 * 1000,
          });
        });
        let timeo = 180;
        this.timeStop = setInterval(() => {
          timeo--;
          if (timeo > 0) {
            document.getElementById("yzm1").innerText =
              this.$t("addLogin.forgetPsd.message8") +
              timeo +
              this.$t("addLogin.forgetPsd.message9");
          } else {
            timeo = 180;
            this.issend = false;
            document.getElementById("yzm1").innerText = this.$t(
              "addLogin.forgetPsd.getCode"
            );
            clearInterval(this.timeStop);
          }
        }, 1000);
      }
    },
    handleLogin() {
      if (!this.check_email()) {
        return false;
      }
      if (this.code.length < 1) {
        this.$message.warning(this.$t("addLogin.forgetPsd.place3"));
        return false;
      }
      let ruleForm = {
        vercode_user: this.code,
        email: this.email,
        vercode: this.vercode,
        tabshow: true,
      };
      this.btnLoading = true;
      this.$store
        .dispatch("Login_email", ruleForm)
        .then((flag) => {
          if (flag === 1) {
            request_joinedProjects()
              .then((res) => {
                this.$store.commit("set_joined_projects", res);
                sessionSave("p_id", res[0].projectId);
                this.set_release_obj(res[0].projectId);
                const backURL = sessionRead("backURL");
                if (
                  backURL === "null" ||
                  backURL === "" ||
                  typeof backURL === "undefined"
                ) {
                  this.$router.push({ path: `/home` });
                } else {
                  sessionRemove("backURL");
                  this.$router.push({ path: backURL });
                }
              })
              .catch(() => {
                this.btnLoading = false;
              });

            getWatermark()
              .then((res) => {
                this.SET_WATERMASK({
                  waterMaskSwitch: res.switchFlag ? true : false,
                  waterMaskValue: res.content ? res.content : "",
                });
              })
              .catch(() => {
                this.btnLoading = false;
              });
          } else {
            this.btnLoading = false;
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.topChange {
  width: 88vw;
  display: flex;
  justify-content: center;

  span {
    display: inline-block;
    cursor: pointer;
    //   font-size: 18px;
    height: 4.2vh;
    width: 44vw;
  }

  .active {
    border-bottom: 1px solid rgba(47, 100, 181, 1);
    color: rgba(47, 100, 181, 1);
    width: 44vw;
  }

  .noactive {
    border-bottom: 1px solid rgba(195, 195, 195, 1);
    width: 100%;
  }
}

.main {
  background: url();
}

.clear {
  color: inherit;
  user-select: none;
}

.main {
  width: 100%;
  display: grid;
  grid-template-columns: 100% 60%;
  // border: 2px solid white;
  border-radius: 20px;

  .inputbox {
    margin-top: 4vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3vh;

    .lobtn {
      width: 88vw;
      display: flex;
      flex-direction: column;
      align-items: center;

      .login_goto {
        font-size: 16px;
        font-weight: 700;
        word-spacing: 10px;
        border-radius: 10px;
        width: 88vw;
        //   margin: 0px 2vh;
        padding: 2vh 0;
      }

      .router {
        width: 100vw;
        font-size: 14px;
        display: flex;
        margin-top: 2vh;
        //   margin-left: 6.5vw;
        align-self: start;

        span {
          color: rgba(137, 138, 140, 1);
        }

        p {
          margin: 0;
          color: rgba(47, 100, 181, 1);
        }
      }
    }

    .yzm {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5.5vh;
      border: 1px solid rgba(48, 100, 143, 1);
      padding-right: 4px;
      width: 35vw;

      p {
        font-size: 14px;
      }
    }

    .yzm1 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5.5vh;
      border: 1px solid rgba(48, 100, 143, 1);
      width: 35vw;

      //   padding-left: 10px;
      p {
        letter-spacing: 2px;
        font-size: 14px;
        margin: 0;
        margin-left: 2px;
      }
    }

    .i_emial {
      // margin-top: -50px;
      // margin-bottom: 100px;
      position: relative;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
    }

    .i_code {
      margin-top: 3vh;
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 88vw;
    }

    .forget {
      width: 88vw;
      display: flex;
      justify-content: flex-end;
      // margin-bottom: 100px;
      word-spacing: 5px;
      font-size: 14px;
      margin-top: 8.2vh;
    }

    .email {
      padding-left: 10vw;
      width: 88vw;
      height: 5.5vh;
      border: 1px solid rgba(195, 195, 195, 1);
      border-radius: 6px;
      outline: none;
    }

    .num {
      padding-left: 5vw;
      width: 50vw;
      height: 5.5vh;
      border: 1px solid rgba(195, 195, 195, 1);
      border-radius: 6px;
      outline: none;
    }

    .all {
      font-size: 16px;
    }

    input::-webkit-input-placeholder {
      padding-left: 15px;
      font-size: 16px;
      color: rgba(195, 195, 195, 1);
      letter-spacing: 2px;
    }
  }

  .frontIcon {
    position: absolute;
    cursor: pointer;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 2.75vh auto;
    margin-left: 11px;
    transform: translateY(-8px);
  }

  .backIcon {
    position: absolute;
    cursor: pointer;
    right: 19px;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 2.75vh auto;
    transform: translateY(-8px);
  }
}

// .r2{
//     cursor: not-allowed !important;
//   pointer-events:none !important;
// }
</style>
